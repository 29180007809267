<script setup>
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import { Link } from "@inertiajs/vue3";
const footerLinks = {
    0: [
      //  { label: "Notre histoire", url: "/" },
       // { label: "Devenir animateur", url: "/" },
        //{ label: "Recrutement", url: "/" },
        //{ label: "Presse", url: "/" },
    ],
    1: [
       /* { label: "Paris", url: "/" },
        { label: "Lyon", url: "/" },
        { label: "Marseille", url: "/" },
        { label: "Toulouse", url: "/" },
        { label: "Bordeaux", url: "/" },
        { label: "Rennes", url: "/" },
        { label: "Lille", url: "/" },
        { label: "Lens", url: "/" },*/
    ],
    2: [
        { label: "Mentions légales", url: "/mentions" },
        { label: "Politique de confidentialité", url: "/rgpd" },
     //   { label: "CGU", url: "/" },
       // { label: "CGV", url: "/" },
       // { label: "Contact", url: "/" },
        //{ label: "Affiliation", url: "/" },
    ],
    3: [
        { label: "Mon compte", url: route("profile.show") },
        // { label: "Comment ça marche ?", url: "/" },
        // { label: "Règle de bonne conduite", url: "/" },
        // { label: "Boutique", url: "/" },
        // { label: "Actualités", url: "/" },
        // { label: "Animations", url: "/" },
        // { label: "Soirées Plaisir Online", url: "/" },
    ],
};

const footerLinkTitles = {
    1: "Rencontrez-vous",
    2: "Mentions",
    3: "Informations",
};
</script>
<template>
    <footer class="bg-dark text-white">
        <div class="container mx-auto py-12">
            <div class="grid md:grid-cols-4 grid-cols-1 gap-8">
                <div class="flex flex-col gap-2">
                    <div class="h-12 flex items-center">
                        <ApplicationLogo class="h-8 w-auto" dark />
                    </div>
                    <div
                        v-for="link in footerLinks[0]"
                        v-if="footerLinks[0].length > 0"
                    >
                        <Link
                            :href="link.url"
                            class="font-medium hover:text-primary transition"
                        >
                            {{ link.label }}
                        </Link>
                    </div>
                </div>
                <div
                    class="flex flex-col gap-2"
                    v-if="footerLinks[1].length > 0"
                >
                    <div
                        class="h-12 flex items-center uppercase text-base font-semibold"
                    >
                        {{ footerLinkTitles[1] }}
                    </div>
                    <div v-for="link in footerLinks[1]">
                        <Link
                            :href="link.url"
                            class="font-medium hover:text-primary transition"
                        >
                            {{ link.label }}
                        </Link>
                    </div>
                </div>
                <div
                    class="flex flex-col gap-2"
                    v-if="footerLinks[2].length > 0"
                >
                    <div
                        class="h-12 flex items-center uppercase text-base font-semibold"
                    >
                        {{ footerLinkTitles[2] }}
                    </div>
                    <div v-for="link in footerLinks[2]">
                        <Link
                            :href="link.url"
                            class="font-medium hover:text-primary transition"
                        >
                            {{ link.label }}
                        </Link>
                    </div>
                </div>
                <div
                    class="flex flex-col gap-2"
                    v-if="footerLinks[3].length > 0"
                >
                    <div
                        class="h-12 flex items-center uppercase text-base font-semibold"
                    >
                        {{ footerLinkTitles[3] }}
                    </div>
                    <div v-for="link in footerLinks[3]">
                        <Link
                            :href="link.url"
                            class="font-medium hover:text-primary transition"
                        >
                            {{ link.label }}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
